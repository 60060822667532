<template>
  <div>
    <container>
      <div slot="left_box_content">
        <component :is="curLeftComponent"></component>
      </div>
      <div slot="right_box_content">
        <component :is="curRightComponent"></component>
      </div>
      <div slot="bottom_box_content">
        <component :is="curBottomComponent"></component>
      </div>
      <div slot="bg_map"></div>
    </container>
  </div>
</template>

<script>
import Container from '@/components/common/Container'
import { sysMixins } from '@/assets/mixins/sys'
// 引入store模块
import taskStoreModule from '@/store/modules/task'
export default {
  mixins: [sysMixins],
  components: {
    Container,
    // left
    SetRepairTask: () => import('@/components/demand/left/SetRepairTask'),
    SetMaintainTask: () => import('@/components/demand/left/SetMaintainTask'),
    SetFillFile: () => import('@/components/demand/left/SetFillFile'),
    SetTempTask: () => import('@/components/demand/left/SetTempTask'),
    UninstallScreen: () => import('@/components/demand/left/UninstallScreen'),
    // bottom
    IndexTable: () => import('@/components/demand/bottom/IndexTable'),
    AddTask: () => import('@/components/demand/bottom/AddTask'),
    DemandDetial: () => import('@/components/demand/bottom/DemandDetial'),
    MaterialManage: () => import('@/components/demand/bottom/MaterialManage'),
    AddIssueTask: () => import('@/components/demand/bottom/AddIssueTask'),
    VisualSetting: () => import('@/components/demand/bottom/VisualSetting'),

    // right
    MaterialRecord: () => import('@/components/demand/right/MaterialRecord')
  },
  created () {
    // 注册动态store模块
    if (!this.$store.hasModule('task')) {
      this.$store.registerModule('task', taskStoreModule)
    }
  },
  mounted () {
    this.init()
  },
  methods: {
    init () {
      this.setShowLeftBlock(false)
      this.setShowRightBlock(false)
      this.setShowMap(false)
      this.setBottomHeight('90%')
      this.setLeftComponent('')
      this.setRightComponent('')
      this.setBottomComponent('IndexTable')
    }
  },
  destroyed () {
    // 卸载installSetting动态store模块
    this.$store.commit('reset_state')
    this.$store.unregisterModule('task')
    this.setShowLeftBlock(true)
    this.setShowMap(true)
    this.setShowRightBlock(true)
    this.setBottomHeight('300px')
    this.setLinkTagArray()
    this.setLinkRouterMaps()
    this.setActivedTagName('')
  }
}
</script>
