import { getStationPage } from '@/api/msp/demandV3'
import { getDevicePage } from '@/api/product/device'
import { getTroublePage } from '@/api/msp/trouble'
import { getTaskItemPage, getPublishingTaskitemPage } from '@/api/msp/taskitem'
import { getTaskPage, getTaskPageWithCompany } from '@/api/msp/task'
import { allFileApproval } from '@/api/msp/file'
import { getResourcePage } from '@/api/product/resource'
import { getCompanyDevicePage } from '@/api/rim/device'

const getDefaultState = () => {
  return {
    pageQuery: {
      pageNumber: 1,
      pageSize: 15
    },
    curTaskId: null,
    tableData: {},
    curDemandType: null,
    selectedResourceIdArray: [],
    opreateType: 1, // 操作类型，1：新增，2：修改
    dataSource: 1, // 数据来源，1：库存资源，2：上报资源
    selectedTaskitemIdArray: [],
    tempSelectedItem: [], // 临时存储分页选中项
    taskStatus: null, // 主任务状态
    selectedTaskitemFiles: [], // 当前选中任务的上传文件
    selectedFaultIdArray: [], // 选中上报故障id集合
    indexSearchParams: null, // 首页搜索参数
    approvalFileList: [],
    approvalFile: {},
    materialManageTab: 'newScreen',
    curDeviceRapairInfo: {} // 当前设备故障信息
  }
}

const state = getDefaultState()

const task = {
  state,
  mutations: {
    reset_state (state) {
      Object.assign(state, getDefaultState())
    },
    set_page_query (state, data) {
      if (data) {
        Object.assign(state.pageQuery, data)
      } else {
        state.pageQuery = getDefaultState().pageQuery
      }
    },
    set_current_taskId (state, data) {
      state.curTaskId = data
    },
    set_table_data (state, data) {
      state.tableData = data
    },
    set_current_demand_type (state, data) {
      state.curDemandType = data
    },
    set_selected_resourceId_array (state, data) {
      state.selectedResourceIdArray = data || []
    },
    set_opreate_type (state, data) {
      state.opreateType = data
    },
    set_data_source (state, data) {
      state.dataSource = data
    },
    set_selected_taskitemid_array (state, data) {
      state.selectedTaskitemIdArray = data || []
    },
    set_selected_taskitem_files (state, data) {
      state.selectedTaskitemFiles = data || []
    },
    set_temp_selected_item (state, data) {
      state.tempSelectedItem = (data instanceof Array) ? data : []
    },
    set_task_status (state, data) {
      state.taskStatus = data
    },
    set_selected_faultid_array (state, data) {
      state.selectedFaultIdArray = (data instanceof Array) ? data : []
    },
    set_index_search_params (state, data) {
      state.indexSearchParams = data
    },
    set_approval_file_list (state, data) {
      state.approvalFileList = data
    },
    set_approval_file (state, data) {
      state.approvalFile = data
    },
    set_material_manage_tab (state, data) {
      state.materialManageTab = data
    },
    set_cur_device_rapair_info (state, data) {
      state.curDeviceRapairInfo = data
    }
  },
  actions: {
    getDeviceData ({ state, commit }, query) {
      const newQuery = Object.assign(state.pageQuery, query)
      commit('set_page_query', newQuery)
      return new Promise((resolve) => {
        getDevicePage(newQuery).then(res => {
          if (res && !res.errcode) {
            commit('set_table_data', res)
          } else {
            commit('set_table_data', {})
          }
          resolve(res)
        })
      })
    },
    /**
     * 分页获取任务数据
     * @param {Object} param0
     * @param {Object} query 查询条件参数
     */
    getTaskData ({ state, commit }, query) {
      const newQuery = Object.assign(state.pageQuery, query)
      commit('set_page_query', newQuery)
      return new Promise((resolve) => {
        if (newQuery.type === 7) {
          // 下刊申请
          getTaskPage(newQuery).then(res => {
            if (res && !res.errcode) {
              commit('set_table_data', res)
            } else {
              commit('set_table_data', {})
            }
            resolve(res)
          })
        } else {
          // 巡检、临时任务和自定义上刊
          getTaskPageWithCompany(newQuery).then(res => {
            if (res && !res.errcode) {
              commit('set_table_data', res)
            } else {
              commit('set_table_data', {})
            }
            resolve(res)
          })
        }
      })
    },
    /**
     * 获取站点数据
     * @param {Object} param0
     * @param {Object} query 查询条件参数
     */
    getStationData ({ state, commit }, query) {
      const newQuery = Object.assign(state.pageQuery, query)
      commit('set_page_query', newQuery)
      return new Promise((resolve) => {
        getStationPage(newQuery).then(res => {
          if (res && !res.errcode) {
            commit('set_table_data', res)
          } else {
            commit('set_table_data', {})
          }
          resolve(res)
        })
      })
    },
    /**
     * 获取移动端上报故障
     * @param {Object} param0
     * @param {Object} query 查询条件参数
     */
    getTroubleData ({ state, commit }, query) {
      const newQuery = Object.assign(state.pageQuery, query)
      commit('set_page_query', newQuery)
      return new Promise((resolve) => {
        getTroublePage(newQuery).then(res => {
          if (res && !res.errcode) {
            commit('set_table_data', res)
          } else {
            commit('set_table_data', {})
          }
          resolve(res)
        })
      })
    },
    /**
     * 获取任务详情列表
     * @param {Object} param0
     * @param {Object} query 查询条件参数
     */
    getTaskitemData ({ state, commit }, query) {
      const newQuery = Object.assign(state.pageQuery, query)
      commit('set_page_query', newQuery)
      return new Promise((resolve) => {
        getTaskItemPage(newQuery).then(res => {
          if (res && !res.errcode) {
            commit('set_table_data', res)
          } else {
            commit('set_table_data', {})
          }
          resolve(res)
        })
      })
    },
    /**
     * 获取当前发布中的资源
     * @param {Object} param0
     * @param {Object} query 查询条件参数
     */
    getPublishingTaskitemData ({ state, commit }, query) {
      const newQuery = Object.assign(state.pageQuery, query)
      commit('set_page_query', newQuery)
      return new Promise((resolve) => {
        getPublishingTaskitemPage(newQuery).then(res => {
          if (res && !res.errcode) {
            commit('set_table_data', res)
          } else {
            commit('set_table_data', {})
          }
          resolve(res)
        })
      })
    },
    /**
     * 获取所有资源数据
     * @param {Object} param0
     * @param {Object} query 查询条件参数
     */
    getAllResourceData ({ state, commit }, query) {
      const newQuery = Object.assign(state.pageQuery, query)
      commit('set_page_query', newQuery)
      return new Promise((resolve) => {
        getResourcePage(newQuery).then(res => {
          if (res && !res.errcode) {
            commit('set_table_data', res)
          } else {
            commit('set_table_data', {})
          }
          resolve(res)
        })
      })
    },
    /**
     * 获取公司所有可见设备数据
     * @param {Object} param0
     * @param {Object} query 查询条件参数
     */
    getAllDeviceData ({ state, commit }, query) {
      const newQuery = Object.assign(state.pageQuery, query)
      commit('set_page_query', newQuery)
      return new Promise((resolve) => {
        getCompanyDevicePage(newQuery).then(res => {
          if (res && !res.errcode) {
            commit('set_table_data', res)
          } else {
            commit('set_table_data', {})
          }
          resolve(res)
        })
      })
    },
    /**
         * 获取文件审批历史记录
         * @param {Object} param0
         * @param {Object} data
         */
    getApprovalFileHistory ({ state, commit }, data) {
      return new Promise((resolve, reject) => {
        allFileApproval({ status: null }).then(res => {
          commit('set_approval_file_list', res)
          resolve(res)
        })
      })
    }
  }
}

export default task
